import script from "./InstallationList.vue?vue&type=script&setup=true&lang=js"
export * from "./InstallationList.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QMarkupTable,QIcon,QCheckbox});
