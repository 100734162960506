<template>
  <q-markup-table flat>
    <thead>
      <tr>
        <th class="text-left">Type aansluiting(en)</th>
        <th class="text-center"></th>
        <th class="text-right">Keuze</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="showElectricity">
        <td class="text-left">
          <q-icon name="bolt" style="font-size: 1.1rem" />Stroom
        </td>
        <td class="text-center"></td>
        <td class="text-right">
          <q-checkbox disable left-label v-model="checkbox" />
        </td>
      </tr>
      <tr v-if="showGas">
        <td class="text-left">
          <q-icon name="whatshot" style="font-size: 1rem" /> Gas
        </td>
        <td class="text-center"></td>
        <td class="text-right">
          <q-checkbox disable left-label v-model="checkbox" />
        </td>
      </tr>
    </tbody>
  </q-markup-table>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";

//* VueUse
const $store = useStore();

//* Refs
const checkbox = true;

//* Computed
// const mono = computed(() =>
//   $store.getters.projectSpecific["MONO"] == "true" ? true : false
// );
const showGas = computed(
  () => $store.getters.projectSpecific["verbruik gas"] || false
);
const showElectricity = computed(() => {
  return (
    $store.getters.projectSpecific["verbruik electra"] ||
    $store.getters.projectSpecific["verbruik electra 2"] ||
    false
  );
});
</script>
